import Link from 'next/link'
import { withRouter } from 'next/router'
import Router from 'next/router'
import Amplify from '../lib/aws'

const signOut = async (evt) => {
  evt.preventDefault();
  try {
    const result = await Amplify.Auth.signOut();
    Router.push('/');
  } catch (e) {
    console.log(e)
  }
};

const Header = ({ session, router: { pathname } }) => {
  return (
    <React.Fragment>

      <header>
      </header>
      {/* <div className="logo-container">
        <img src="/static/claire-logo.png" width="90px" />
      </div> */}
      <style jsx>{`
          header {
            height: 60px;
            padding: 0px 15px;
            background-color: #2d75be;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          a {
            font-size: 14px;
            margin-right: 15px;
            text-decoration: none;
            color: white;
          }

          .logout {
            cursor: pointer;
          }

          .is-active {
            text-decoration: underline;
          }

          .logo-container{
            margin: 2.0em;
            display: flex;
            justify-content: center;
            align-items: center;
          }
      `}</style>
    </React.Fragment>
  )
}

export default withRouter(Header)
