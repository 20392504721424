import { Button } from 'antd';


// const Button = (props) => {
//     const {variant, color} = props;
//     let _variant = variant ? variant : 'primary';
//     let _color = color ? color : 'blue';

//     let classNames = '';
//     if(_color === 'secondary'){
//         classNames += 'secondary';
//     }
//     if(_variant === 'outline'){
//         classNames += 'outline';
//     }

//     return (
//         <div>
//             <button {...props} className={classNames} />
//             <style jsx>{`
//             button{
//                 display: flex;
//                 position: relative;
//                 max-width: 100%;
//                 margin: 0;
//                 padding: .5rem 1.25rem;
//                 transition: all .15s ease-in-out;
//                 border: 1px solid;
//                 border-radius: 4px;
//                 border-color: #1a91eb;
//                 color: #fff;
//                 font-size: .875rem;
//                 font-weight: 400;
//                 line-height: 1.5;
//                 white-space: nowrap;
//                 cursor: pointer;
//                 overflow: hidden;
//                 vertical-align: middle;
//                 appearance: none;
//                 user-select: none;
//                 background: #2ea1f8;
//                 background: linear-gradient(180deg,#2ea1f8,#1990ea);
//               }
//               button:hover{
//                 background: linear-gradient(180deg,#1a98f7,#1485db);
//               }
//               .secondary{
//                 background: linear-gradient(180deg,#fff,#f2f4f7);
//                 border-color: #dfe3e9;
//                 color: #354052;
//               }
//               .secondary:hover{
//                 background: linear-gradient(180deg,#fcfcfc,#eceff3);
//                 color: #354052;
//               }
//               button:disabled{
//                 opacity: 0.5;
//               }
//             `}</style>
//         </div>
//     )
// }

export default Button;
